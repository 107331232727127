import {InputWithButton} from './input-with-button';
import React, {FormEvent} from 'react';

export const EmailForm = (props: {
  inputPlaceholder: string;
  buttonText: string;
  inputWidth: number | string;
  buttonColor: 'violet' | 'green' | 'blue'
  onSubmit?: (email: string) => void;
}): JSX.Element => {

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    props.onSubmit?.(((e.target as HTMLFormElement).elements.namedItem('email') as HTMLInputElement).value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputWithButton
        inputPlaceholder={props.inputPlaceholder}
        buttonText={props.buttonText}
        buttonColor={props.buttonColor}
        inputWidth={props.inputWidth}
      />
    </form>
  );
};
