import * as React from 'react';
import { graphql } from 'gatsby';
import './styles.scss';
import {MarkdownRemark} from '../models/markdown-remark';
import {TitleSection} from '../components/cost-impact/title-section';
import {BackgroundFigure} from '../components/shared/background-figure';
import {BasePageData, BasePageFooter, BasePageHeader} from '../helpers/base-page';
import {useState} from 'react';
import {RegularSectionModel} from '../models/regular-section-model';
import { RegularSection } from '../components/shared/regular-section';

interface CostImpactPageData extends BasePageData {
  costImpact: MarkdownRemark<{
    seoTitle: string;
    title: string;
    heroImage: string;
    altText: string;
    subTitle: string;
    subList: Array<{listItem: string;}>
    inputPlaceholder: string;
    buttonText: string;
    subListTitle: string;
    subSections: Array<RegularSectionModel>;
  }>;
}

const CostImpactPage = ({data}: {data: CostImpactPageData}): JSX.Element => {

  const [isFormVisible, setFormVisibility] = useState(false);
  const [contactEmail, setContactEmail] = useState<string | undefined>(undefined);

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.costImpact.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
        isFormVisible={isFormVisible}
        email={contactEmail}
        onFormClose={() => setFormVisibility(false)}
      />
      <TitleSection
        title={data.costImpact.edges[0].node.frontmatter.title}
        text={data.costImpact.edges[0].node.frontmatter.subTitle}
        image={data.costImpact.edges[0].node.frontmatter.heroImage}
        alt={data.costImpact.edges[0].node.frontmatter.altText}
        listHeader={data.costImpact.edges[0].node.frontmatter.subListTitle}
        list={data.costImpact.edges[0].node.frontmatter.subList.map(x => x.listItem)}
        inputPlaceholder={data.costImpact.edges[0].node.frontmatter.inputPlaceholder}
        buttonText={data.costImpact.edges[0].node.frontmatter.buttonText}
        onSubmit={email => {setFormVisibility(true); setContactEmail(email);}}
      />
      <div className={'desktop-only'}>
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 76, height: 203}}
          position={{left: 0, top: 1100}}
        />
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 36, height: 189}}
          position={{right: 0, top: 1970}}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#ffc02a'}
          size={{width: 23, height: 46}}
          position={{right: 0, top: 750}}
          style={{
            zIndex: 0,
            borderRadius: 23,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#67dffa'}
          size={{width: 36, height: 72}}
          position={{right: 0, top: 1690}}
          style={{
            zIndex: 0,
            borderRadius: 36,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#00e0dd'}
          size={{width: 22, height: 22}}
          position={{left: 52, top: 1570}}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#ffc02a'}
          size={{width: 35, height: 35}}
          position={{right: 286, bottom: 960}}
        />
        <div style={{
          position: 'absolute',
          left: 0,
          top: 1970,
          width: 86,
          height: 155,
          overflow: 'hidden'
        }}>
          <BackgroundFigure
            type={'triangle'}
            color={'#0089ff'}
            size={{width: 60, height: 60}}
            position={{right: 60, bottom: 37}}
            style={{
              transform: `
                rotate(120deg)
                skewX(-30deg)
                scale(1,.866)
              `
            }}
          />
        </div>
      </div>
      {
        data.costImpact.edges[0].node.frontmatter.subSections.map((s, index, arr) => (
          <RegularSection key={index} {...s.section} style={{
            ...(index === arr.length - 1 && {borderBottom: 'none'})
          }} />
        ))
      }
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default CostImpactPage;

export const pageQuery = graphql`
  {
    ...BaseData
    costImpact: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/costImpact.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            seoTitle
            title
            heroImage
            altText
            subTitle
            inputPlaceholder
            buttonText
            subListTitle
            subList {
              listItem
            }
            subSections {
              section {
                alignment
                label
                color
                background
                title
                image
                altText
                subText
              }
            }
          }
        }
      }
    }
  }
`;
