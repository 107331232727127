import React, {CSSProperties} from 'react';
import styled from 'styled-components';
import BackgroundStrips from '../../images/background-strips.svg';

const Strips = styled.div`
  position: absolute;
  z-index: -1;
  background: url(${BackgroundStrips}) no-repeat center;
  background-size: contain;
`;

const Circle = styled.div`
  position: absolute;
  z-index: -1;
  background: ${props => props.color};
  border-radius: 50%;
`;

const Triangle = styled.div`
  position: absolute;
  z-index: -1;
  background-color: ${props => props.color};
  text-align: left;
  transform: rotate(-60deg) skewX(-30deg) scale(1, .866);

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: inherit;
  }

  &,
  &:before,
  &:after {
    width: inherit;
    height: inherit;
    border-top-right-radius: 30%;
  }

  &:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, .707) translate(0, -50%);
  }

  &:after {
    transform: rotate(135deg) skewY(-45deg) scale(.707, 1.414) translate(50%);
  }
`;

const dots = (dotSpace: number, dotSize: number, bgColor: string, dotColor: string) => `
  background:
    linear-gradient(90deg, ${bgColor} ${dotSpace - dotSize}px, transparent 1%) center,
    linear-gradient(${bgColor} ${dotSpace - dotSize}px, transparent 1%) center, ${dotColor};
  background-size: ${dotSpace}px ${dotSpace}px;
`;

const DotCircle = styled.div`
  ${dots(16, 2, 'white', '#e6ecfb')}
  border-radius: 100px;
  position: absolute;
  z-index: -1;
`;

export const BackgroundFigure = (props: {
  type: 'strips' | 'circle' | 'triangle' | 'dots',
  color: string,
  size?: {
    width?: number | string;
    height?: number | string;
  },
  position?: {
    top?: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;
  },
  style?: CSSProperties
}): JSX.Element => {
  switch (props.type) {
    case 'strips':
      return <Strips color={props.color} style={{
        width: props.size?.width,
        height: props.size?.height,
        top: props.position?.top,
        left: props.position?.left,
        bottom: props.position?.bottom,
        right: props.position?.right,
        ...props.style
      }} />;
    case 'circle':
      return <Circle color={props.color} style={{
        width: props.size?.width,
        height: props.size?.height,
        top: props.position?.top,
        left: props.position?.left,
        bottom: props.position?.bottom,
        right: props.position?.right,
        ...props.style
      }} />;
    case 'triangle':
      return <Triangle color={props.color} style={{
        width: props.size?.width,
        height: props.size?.height,
        top: props.position?.top,
        left: props.position?.left,
        bottom: props.position?.bottom,
        right: props.position?.right,
        ...props.style
      }} />;
    case 'dots':
      return <DotCircle
        style={{
          width: props.size?.width,
          height: props.size?.height,
          top: props.position?.top,
          left: props.position?.left,
          bottom: props.position?.bottom,
          right: props.position?.right,
          ...props.style
        }}
      />;
  }
};
