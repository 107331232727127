import React from 'react';
import Background from '../../images/impact-background.png';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {EmailForm} from '../shared/email-form';
import {device} from '../../models/devices';

const Container = styled.section`
  background: url(${Background}) no-repeat bottom center;
  background-size: cover;
  padding-top: 166px;
  padding-bottom: 152px;

  @media screen and ${device.tablet} {
    padding-top: 115px;
    padding-bottom: 80px;
    border-bottom: 1px solid #e6ecfb;
    background-position-y: calc(100% - 80px);
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1170px;
  margin: 0 auto;
  grid-row-gap: 20px;
  grid-template-areas:
    'header image'
    'text image'
    'list image';

  @media screen and ${device.tablet} {
    grid-template-columns: 1fr;
    grid-template-areas: 'header' 'text' 'list' 'image';
    padding: 0 24px;
    grid-row-gap: 24px;
  }
`;

const Header = styled.h1`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.33;
  color: white;
  grid-area: header;
  margin: 19px 0 10px;

  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.5em;
    margin: 0;
  }
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.78;
  color: #cfe7fc;
  grid-area: text;
  letter-spacing: -0.1px;

  @media screen and ${device.tablet} {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 8px;
  }
`;

const ListItem = styled.li`
  font-size: 16px;
  font-weight: 500;
  color: white;
  line-height: 32px;
  display: flex;
  align-items: baseline;

  p { display: inline-block; }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 12px;
    border-radius: 50%;
    background: radial-gradient(circle,
      rgba(0,197,245,1) 0%,
      rgba(0,197,245,1) 35%,
      rgba(0,197,245,0.34) 35%,
      rgba(0,197,245,0.34) 100%
    );
  }
`;

const Image = styled.img`
  max-width: 571px;
  grid-area: image;
  width: 100%;

  @media screen and ${device.tablet} {
    margin: 16px auto 0;
  }
`;

export const TitleSection = (props: {
  title: string;
  text: string;
  image: string;
  alt: string;
  listHeader: string;
  list: string[];
  inputPlaceholder: string;
  buttonText: string;
  onSubmit?: (email: string) => void;
}): JSX.Element => {
  return (
    <Container>
      <Wrapper>
        <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
        <Text><ReactMarkdown>{props.text}</ReactMarkdown></Text>
        <div style={{gridArea: 'list'}}>
          <span style={{
            fontSize: 16,
            fontWeight: 600,
            color: 'white',
            display: 'block',
            marginBottom: 15
          }}>{props.listHeader}</span>
          <ul style={{marginBottom: 18}}>
            {
              props.list.map((text, index) => (
                <ListItem key={index}><ReactMarkdown>{text}</ReactMarkdown></ListItem>
              ))
            }
          </ul>
          <EmailForm
            inputPlaceholder={props.inputPlaceholder}
            buttonText={props.buttonText}
            buttonColor={'violet'}
            inputWidth={339}
            onSubmit={email => props.onSubmit?.(email)}
          />
        </div>
        <Image src={props.image} alt={props.alt}/>
      </Wrapper>
    </Container>
  );
};
